import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from './Modal';
import placeholderImage from '../img/placeholder-img.png';


const KidsTab = () => {
    const [error, setError] = useState(null);
    const [showAddKidModal, setShowAddKidModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showInactiveModal, setShowInactiveModal] = useState(false);
    const [showActiveModal, setShowActiveModal] = useState(false);

    const [kidToDelete, setKidToDelete] = useState(null);
    const [kidToInactive, setKidToInactive] = useState(null);
    const [kidToActive, setKidToActive] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [photo, setPhoto] = useState(null);
    const [photoSrc, setPhotoSrc] = useState(placeholderImage);

    const [selectedFilter, setSelectedFilter] = useState('active');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [selectedRequiredOptions, setSelectedRequiredOptions] = useState([]);
    const [newRequiredOptions, setNewRequiredOptions] = useState([]);

    // State for Optional Options
    const [newOptionalOptions, setNewOptionalOptions] = useState([]);

    // State to manage the list of selected optional options (from dropdown)
    const [selectedOptionalOptions, setSelectedOptionalOptions] = useState([]);
    const [menuItems, setMenuItems] = useState([]);

    const [requiredOptions, setRequiredOptions] = useState([]);
    const [optionalOptions, setOptionalOptions] = useState([]);
    const [itemName, setItemName] = useState('');
    const [itemDescription, setItemDescription] = useState('');
    const [price, setPrice] = useState('');
    const [categoryId, setCategoryId] = useState('');
    const [categories, setCategories] = useState([]);

    const fetchMenuItems = async () => {
        setIsSubmitting(true); // Set isSubmitting to true to show the spinner
        try {
            const response = await axios.get('https://admin.lkofoods.com/php/menu-items.php');
            
            
            setTimeout(() => {
                setMenuItems(response.data); // Set the fetched menu items
                setIsSubmitting(false); // Set isSubmitting to false to hide the spinner
            }, 1000); // Add a slight delay to simulate loading
        } catch (error) {
            console.error('Error fetching menu items:', error);
            setIsSubmitting(false); // Ensure spinner is hidden on error
        }
    };
    

    
    
    const fetchCategories = async () => {
        setIsSubmitting(true);
        try {
            const response = await fetch('https://admin.lkofoods.com/php/categories.php');
            const data = await response.json();
            setTimeout(() => {
                setCategories(data);
                setIsSubmitting(false);
            }, 1000);
        } catch (error) {
            console.error('Error fetching categories:', error);
            setIsSubmitting(false);
        }
    };

    const fetchOptions = async () => {
        try {
            const response = await axios.get('https://admin.lkofoods.com/php/fetch-options.php');
            setRequiredOptions(response.data.required_options);
            setOptionalOptions(response.data.optional_options);
        } catch (error) {
            console.error('Error fetching options:', error);
        }
    };

    useEffect(() => {
        fetchMenuItems();
        fetchCategories();
        fetchOptions();
    }, []);

    const setErrorWithTimeout = (errorMessage) => {
        setError(errorMessage);
        if (errorMessage) {
            setTimeout(() => {
                setError('');
            }, 3000);
        }
    };




    const confirmMarkAsInactive = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!kidToInactive || !kidToInactive.kid_id) {
            setErrorWithTimeout('Invalid kid selected for inactivation');
            setIsSubmitting(false);
            return;
        }

        // Fetch checked-in kids
        let checkedInKids = [];
        try {
            const response = await fetch('https://admin.lkofoods.com/php/checkedin-kids.php');
            if (!response.ok) throw new Error('Network response was not ok');
            checkedInKids = await response.json();
        } catch (error) {
            console.error('Error fetching checked-in kids:', error);
            setIsSubmitting(false);
            return;
        }

        // Check if the selected kid is checked in
        const isKidCheckedIn = checkedInKids.some(kid => kid.kid_id === kidToInactive.kid_id);
        if (isKidCheckedIn) {
            setErrorWithTimeout('This kid has a pending check-out');
            setIsSubmitting(false);
            return;
        }

        // Proceed to mark the kid as inactive
        try {
            const response = await fetch(`https://admin.lkofoods.com/php/fhcc-kids.php?kid_id=${kidToInactive.kid_id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ active: false }),
            });

            const data = await response.json();
            if (data.status === 'success') {
                showSuccess('Kid marked as inactive');
            } else {
                setErrorWithTimeout('Error marking kid as inactive');
            }
        } catch (error) {
            setErrorWithTimeout('Error marking kid as inactive');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowInactiveModal(false);
                fetchMenuItems();
            }, 500);
        }
    };


    const confirmMarkAsActive = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);

        if (!kidToActive || !kidToActive.kid_id) {
            setErrorWithTimeout('Invalid kid selected for activation');
            setIsSubmitting(false);
            return;
        }

        try {
            const response = await fetch(`https://admin.lkofoods.com/php/fhcc-kids.php?kid_id=${kidToActive.kid_id}`, {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ active: true }),
            });

            const data = await response.json();
            if (data.status === 'success') {
            } else {
                setErrorWithTimeout('Error marking kid as active');
            }
        } catch (error) {
            setErrorWithTimeout('Error marking kid as active');
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                setShowActiveModal(false);
                fetchMenuItems();
                showSuccess('Kid marked as active');
            }, 500);
        }
    };




    const handleFilterChange = (filter) => {
        setSelectedFilter(filter);
        // fetchKids(filter);
    };


    const handleAddKidClick = () => {
        setShowAddKidModal(true);
        resetFormState();
        fetchOptions();
    };

    const handleCloseModal = () => {
        setShowAddKidModal(false);
        setShowDeleteModal(false);
        setShowInactiveModal(false);
        resetFormState();
    };

    const resetFormState = () => {
        setItemName('');
        setItemDescription('');
        setPrice('');
        setCategoryId('');
        setSelectedRequiredOptions([]);
        setNewRequiredOptions([]);
        setSelectedOptionalOptions([]);
        setNewOptionalOptions([]);
        setRequiredOptions([]);
        setOptionalOptions([]);
        setPhoto(null);
        setPhotoSrc(placeholderImage);
    };


    const handleAddSubmit = async (e) => { 
        e.preventDefault();
    
        // Validate required fields for menu item
        if (!itemName || !price || !categoryId) {
            setErrorWithTimeout('Please fill in all required fields.');
            return;
        }
    
        setIsSubmitting(true);
    
        // Create a FormData object to handle both text data and the file
        const formData = new FormData();
        formData.append('_method', 'POST'); // Simulate POST request
        formData.append('item_name', itemName);
        formData.append('item_description', itemDescription);
        formData.append('price', price);
        formData.append('category_id', categoryId);
    
        // Append selected required options (if any)
        formData.append('selected_required_options', JSON.stringify(selectedRequiredOptions || []));
    
        // Append selected optional options (if any)
        formData.append('selected_optional_options', JSON.stringify(selectedOptionalOptions || []));
    
        // Append new required options (if any)
        formData.append('new_required_options', JSON.stringify(newRequiredOptions || []));
    
        // Append new optional options (if any)
        formData.append('new_optional_options', JSON.stringify(newOptionalOptions || []));
    
        // Append the photo file if it's uploaded
        if (photo) {
            formData.append('photo', photo);
        }
    
        try {
            await axios.post('https://admin.lkofoods.com/php/menu-items.php', formData, {
                headers: {
                    // 'Content-Type': 'multipart/form-data', // Axios will set this header automatically when using FormData
                },
            });
    
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems(); 
                showSuccess('Menu item added successfully');
            }, 500);
        } catch (error) {
            setErrorWithTimeout('Error adding menu item.');
            setIsSubmitting(false);
            console.error('Error adding menu item:', error);
        }
    };
   
    const handleDeleteKid = (kid) => {
        setKidToDelete(kid);
        setShowDeleteModal(true);
    };

    const handleMarkAsInactive = (kid) => {
        setKidToInactive(kid);
        setShowInactiveModal(true);
    };

    const handleMarkAsActive = (kid) => {
        setKidToActive(kid);
        setShowActiveModal(true);
    };

    const confirmDeleteKid = async (e) => {
        e.preventDefault();
        setIsSubmitting(true);
        try {
            await axios.delete(`https://admin.lkofoods.com/php/fhcc-kids.php?kid_id=${kidToDelete.kid_id}`);
        } catch (error) {
            setErrorWithTimeout('Error deleting kid.');
            console.error('Error deleting kid:', error);
        } finally {
            setTimeout(() => {
                setIsSubmitting(false);
                handleCloseModal();
                fetchMenuItems();
                showSuccess('Kid deleted successfully');
            }, 500);
        }
    };

    const showSuccess = (message) => {
        setSuccessMessage(message);
        setShowSuccessMessage(true);
        setTimeout(() => setShowSuccessMessage(false), 3000);
    };

    // const capitalizeFirstLetter = (string) => {
    //     if (typeof string !== 'string' || string.length === 0) return '';
    //     return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    // };

    

    // Handle photo selection with size check
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        const maxSize = 5 * 1024 * 1024; // 5MB limit

        if (file) {
            // Check file size
            if (file.size > maxSize) {
                alert('File size exceeds the 5MB limit.');
                e.target.value = ''; // Reset file input
                return;
            }

            // Revoke previous photo URL if it exists and isn't the placeholder
            if (photoSrc && photoSrc !== placeholderImage) {
                URL.revokeObjectURL(photoSrc);
            }

            // Set the new photo source and file
            const newPhotoSrc = URL.createObjectURL(file);
            setPhotoSrc(newPhotoSrc);
            setPhoto(file);
        }
    };


    const handleRemovePhoto = () => {
        // Revoke the object URL if there is an uploaded image
        if (photoSrc && photoSrc !== placeholderImage) {
            URL.revokeObjectURL(photoSrc);
        }

        // Reset to placeholder image and clear the file input
        setPhoto(null);
        setPhotoSrc(placeholderImage);
        document.getElementById('fileInput').value = ''; // Clear file input field
    };

    
    // Handle selection of a required option
    const handleRequiredOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = requiredOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedRequiredOptions.some(option => option.id === selectedOption.id)) {
                setSelectedRequiredOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };

     // Handle selection of an optional option
     const handleOptionalOptionSelect = (optionId) => {
        if (optionId) {
            const selectedOption = optionalOptions.find(option => option.id === optionId);
            if (selectedOption && !selectedOptionalOptions.some(option => option.id === selectedOption.id)) {
                setSelectedOptionalOptions(prevState => [
                    ...prevState,
                    selectedOption
                ]);
            }
        }
    };
    // Handle input changes for the new required option fields
    const handleNewOptionNameChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };

    const handleNewOptionPriceChange = (e, index) => {
        const updatedOptions = [...newRequiredOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewRequiredOptions(updatedOptions);
    };
    // Handle removing a required option
    const handleRemoveRequiredOption = (index) => {
        const updatedOptions = selectedRequiredOptions.filter((_, i) => i !== index);
        setSelectedRequiredOptions(updatedOptions);
    };


    // Function to remove a newly added required option
    const handleRemoveNewRequiredOption = (index) => {
        const updatedOptions = newRequiredOptions.filter((_, i) => i !== index);
        setNewRequiredOptions(updatedOptions);
    };

    // Function to display a new required option input field when "Add Option" is clicked
    const handleAddNewRequiredOption = (e) => {
        e.preventDefault();
        setNewRequiredOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'required' } // New entry with empty name, price, and type
        ]);
    };




   

    // Handle removing an optional option
    const handleRemoveOptionalOption = (index) => {
        const updatedOptions = selectedOptionalOptions.filter((_, i) => i !== index);
        setSelectedOptionalOptions(updatedOptions);
    };

    // Add a new input field for entering a new optional option
    const handleAddNewOptionalOption = (e) => {
        e.preventDefault();
        setNewOptionalOptions(prevState => [
            ...prevState,
            { option_name: '', option_price: '', isNew: true, option_type: 'optional' } // New entry with empty name, price, and type
        ]);
    };

    // Handle input changes for the new optional option fields
    const handleNewOptionalOptionNameChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_name = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    const handleNewOptionalOptionPriceChange = (e, index) => {
        const updatedOptions = [...newOptionalOptions];
        updatedOptions[index].option_price = e.target.value;
        setNewOptionalOptions(updatedOptions);
    };

    // Remove a new optional option input field
    const handleRemoveNewOptionalOption = (index) => {
        const updatedOptions = newOptionalOptions.filter((_, i) => i !== index);
        setNewOptionalOptions(updatedOptions);
    };


    return (
        <div className="kids-tab-content">
            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}
            {showSuccessMessage && <div className="success-message">{successMessage}</div>}
            <div className="filter-dropdown">
                <select
                    id="kid-status-filter" className="filter-btn"
                    value={selectedFilter}
                    onChange={(e) => handleFilterChange(e.target.value)}
                >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                    <option value="all">All</option>
                </select>
            </div>
            <div className="top-right top-right-user">
                <button className="profiles-btn add-btn" onClick={handleAddKidClick}>
                    Add Item
                </button>
            </div>
            <div className="kids-list">
                {menuItems.length === 0 ? (
                    <div className="no-users-found">No items founds</div>
                ) : (
                    menuItems.map((item, index) => (
                        <div key={item.id} className="kid">
                            <div className="kid-header">
                                <span className="kid-number">{index + 1}</span>
                                {item.image ? (
                                    <img
                                        src={`https://admin.lkofoods.com/uploads/${item.image}`}
                                        alt="Kid"
                                        className="kid-photo"
                                    />
                                ) : (
                                    <img
                                        src={placeholderImage}
                                        alt="Placeholder-img"
                                        className="kid-photo"
                                    />
                                )}

                                <span className="kid-name">
                                    {item.item_name}
                                </span>
                            </div>

                            {/* Conditionally render buttons based on the selected filter */}
                            <div className="action-buttons">
                                {selectedFilter === "active" && (
                                    <>
                                        <button className="view-btn">
                                            <i className="fas fa-eye"></i>
                                        </button>
                                        <button className="edit-btn">
                                            <i className="fas fa-pencil-alt"></i>
                                        </button>
                                        <button className="inactive-btn" onClick={() => handleMarkAsInactive(item)}>
                                            <i className="fas fa-ban"></i>
                                        </button>
                                        <button className="delete-btn" onClick={() => handleDeleteKid(item)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </>
                                )}

                                {selectedFilter === "inactive" && (
                                    <>
                                        <button className="view-btn">
                                            <i className="fas fa-eye"></i>
                                        </button>
                                        <button className="inactive-btn" onClick={() => handleMarkAsActive(item)}>
                                            <i className="fas fa-arrow-circle-up"></i>
                                        </button>
                                        <button className="delete-btn" onClick={() => handleDeleteKid(item)}>
                                            <i className="fas fa-trash-alt"></i>
                                        </button>
                                    </>
                                )}

                                {selectedFilter === "all" && null}
                            </div>
                        </div>
                    ))
                )}
            </div>

            {/* Add Kid Modal */}
            {showAddKidModal && (
                <Modal
                    title="Add Menu Item"
                    onClose={handleCloseModal}
                    onSubmit={handleAddSubmit}
                    submitButtonText="Add Menu Item"
                    showCancel={false}
                    showSubmitButton={true}
                    className="modal-content"
                    requiredOptions={requiredOptions}
                    optionalOptions={optionalOptions}
                >
                    <div className="profiles-form">
                        {/* Other form fields */}
                        <div className="form-group">
                            <label>Photo</label>
                            {/* Display either the uploaded photo or the placeholder */}
                            <img
                                className='item-photo'
                                src={photoSrc}
                                alt="Menu Item"
                                style={{ width: '250px', height: '150px', borderRadius: '10%', margin: 'auto' }}
                            />

                            {/* Hidden file input field */}
                            <input
                                type="file"
                                accept="image/jpeg, image/jpg, image/png, image/webp"
                                onChange={handlePhotoChange}
                                id="fileInput"
                                style={{ display: 'none' }}
                            />

                            {/* Buttons for choosing or removing the photo */}
                            <div className='photo-buttons'>
                                <label
                                    htmlFor="fileInput"
                                    className='photo-btn'
                                >
                                    Choose file
                                </label>
                                {/* Remove photo button, only displayed if a photo is uploaded */}
                                {photo && (
                                    <label className="photo-btn photo-btn2" onClick={handleRemovePhoto}>
                                        <i className="fas fa-times"></i>
                                    </label>
                                )}
                            </div>

                            {/* Display the name of the selected file or a placeholder text */}
                            <div id="fileName">
                                {photo ? photo.name : 'No file chosen'}
                            </div>
                        </div>

                        <div className="form-group">
                            <label>Item Name</label>
                            <input
                                type="text"
                                value={itemName}
                                onChange={(e) => setItemName(e.target.value)}
                                required
                            />
                        </div>

                        <div className="form-group">
                            <label>Item Description</label>
                            <textarea
                                value={itemDescription}
                                rows={4}
                                onChange={(e) => setItemDescription(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Price (£)</label>
                            <input
                                type="number"
                                step="0.01"
                                value={price}
                                onChange={(e) => setPrice(e.target.value)}
                            />
                        </div>

                        <div className="form-group">
                            <label>Category</label>
                            <div className="select-wrapper">
                                <select
                                    value={categoryId}
                                    onChange={(e) => setCategoryId(e.target.value)}
                                    required
                                >
                                    <option value="">Select Category</option>
                                    {categories.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.category_name}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        {/* Required Options Section */}
                        <div className="form-group">
                            <label>Required Options</label>
                            <div className="select-wrapper">
                                <select onChange={(e) => handleRequiredOptionSelect(e.target.value)} defaultValue="">
                                    <option value="">Select Required Option</option>
                                    {/* Assuming requiredOptions is fetched and mapped */}
                                    {requiredOptions.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.option_name} (£{option.option_price})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Display selected options */}
                            {selectedRequiredOptions.map((option, index) => (
                                <div key={index} className="selected-option">
                                    <input
                                        type="text"
                                        value={`${option.option_name} - £${option.option_price}`}
                                        readOnly
                                    />
                                    <button onClick={() => handleRemoveRequiredOption(index)}>Remove</button>
                                </div>
                            ))}



                            {/* Display new required option input fields */}
                            {newRequiredOptions.map((option, index) => (
                                <div key={index} className="new-option-fields">
                                    <input
                                        type="text"
                                        value={option.option_name}
                                        onChange={(e) => handleNewOptionNameChange(e, index)}
                                        placeholder="Option Name"
                                    />
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={option.option_price}
                                        onChange={(e) => handleNewOptionPriceChange(e, index)}
                                        placeholder="Price"
                                    />
                                    <button type="button" onClick={() => handleRemoveNewRequiredOption(index)}>Remove</button>
                                </div>
                            ))}

                            {/* Add new required option button */}
                            <button type="button" onClick={handleAddNewRequiredOption}>Add Option</button>
                        </div>


                        {/* Optional Options Section */}
                        <div className="form-group">
                            <label>Optional Options</label>
                            <div className="select-wrapper">
                                <select onChange={(e) => handleOptionalOptionSelect(e.target.value)} defaultValue="">
                                    <option value="">Select Optional Option</option>
                                    {optionalOptions.map((option) => (
                                        <option key={option.id} value={option.id}>
                                            {option.option_name} (+£{option.option_price})
                                        </option>
                                    ))}
                                </select>
                            </div>

                            {/* Display selected options */}
                            {selectedOptionalOptions.map((option, index) => (
                                <div key={index} className="selected-option">
                                    <input
                                        type="text"
                                        value={`${option.option_name} - £${option.option_price}`}
                                        readOnly
                                    />
                                    <button type="button" onClick={() => handleRemoveOptionalOption(index)}>Remove</button>
                                </div>
                            ))}

                            {/* Display new optional option input fields */}
                            {newOptionalOptions.map((option, index) => (
                                <div key={index} className="new-option-fields">
                                    <input
                                        type="text"
                                        value={option.option_name}
                                        onChange={(e) => handleNewOptionalOptionNameChange(e, index)}
                                        placeholder="Option Name"
                                    />
                                    <input
                                        type="number"
                                        step="0.01"
                                        value={option.option_price}
                                        onChange={(e) => handleNewOptionalOptionPriceChange(e, index)}
                                        placeholder="Price"
                                    />
                                    <button type="button" onClick={() => handleRemoveNewOptionalOption(index)}>Remove</button>
                                </div>
                            ))}
                            {/* Add new optional option button */}
                            <button type="button" onClick={handleAddNewOptionalOption}>Add Option</button>

                        </div>
                        {error && (
                            <div className="error-overlay">
                                <div className='error-overlay-container'>
                                    <i className="fas fa-exclamation-triangle"></i>
                                    <p className="error-overlay-message">{error}</p>
                                </div>
                            </div>
                        )}
                    </div>
                </Modal>
            )}

            {isSubmitting && (
                <div className="spinner-overlay">
                    <div className="spinner"></div>
                </div>
            )}

            


            {/* Delete Kid Modal */}
            {showDeleteModal && (
                <Modal
                    title="Delete Kid"
                    onClose={handleCloseModal}
                    onSubmit={confirmDeleteKid}
                    submitButtonText="Delete Kid"
                    showCancel={true}
                    showSubmitButton={true}
                    className="delete-modal-content"
                >
                    <div className="delete-modal-heading">
                        <p>Are you sure you want to permanently delete this kid from the database?</p>
                    </div>
                </Modal>
            )}

            {/* Inactive Kid Modal */}
            {showInactiveModal && (
                <Modal
                    title="Mark Kid as Inactive"
                    onClose={handleCloseModal}
                    onSubmit={confirmMarkAsInactive}
                    submitButtonText="Mark as Inactive"
                    showCancel={true}
                    showSubmitButton={true}
                    className="delete-modal-content"
                >
                    <div className="delete-modal-heading">
                        <p>Are you sure you want to mark this kid as inactive?</p>
                    </div>
                </Modal>

            )}
            {error && (
                <div className="error-overlay">
                    <div className='error-overlay-container'>
                        <i class="fas fa-exclamation-triangle"></i>
                        <p className="error-overlay-message">{error}</p>
                    </div>
                </div>
            )}
            {/* Active Kid Modal */}
            {showActiveModal && (
                <Modal
                    title="Mark Kid as Active"
                    onClose={handleCloseModal}
                    onSubmit={confirmMarkAsActive}
                    submitButtonText="Mark as Active"
                    showCancel={true}
                    showSubmitButton={true}
                    className="delete-modal-content"
                >
                    <div className="delete-modal-heading">
                        <p>Are you sure you want to mark this kid as active?</p>
                    </div>
                </Modal>
            )}
        </div>
    );
};

export default KidsTab;
