import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from './Modal'; // Import the Modal component

const Header = ({ username }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const navigate = useNavigate();

  const handleLogout = () => {
    // Clear session storage
    sessionStorage.removeItem('isAuthenticated');
    sessionStorage.removeItem('username');

    // Redirect to sign-in page
    navigate('/');
  };

  const handleModalSubmit = (e) => {
    e.preventDefault();
    handleLogout();
  };

  const headerStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px 20px',
    backgroundColor: '#f8f9fa',
    borderBottom: '1px solid #e0e0e0',
  };

  const userIconContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    cursor: 'pointer', // Add pointer cursor
  };

  const logoutButtonStyle = {
    display: showLogoutButton ? 'block' : 'none',
    position: 'absolute',
    top: '100%',
    right: '0',
    marginTop: '5px',
    padding: '5px 10px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    zIndex: '1000', // Ensure the button is above other elements
  };

  return (
    <header style={headerStyle}>
      <img src="img/logo1.png" alt="logo" />
      <h2>LKO FOODS ADMIN PANEL</h2>
      <div
        className="user-icon"
        style={userIconContainerStyle}
        onClick={() => setShowLogoutButton(!showLogoutButton)}
      >
        <i className="fas fa-user-circle"></i>
        <span>{username}</span>
        {showLogoutButton && (
          <button
            style={logoutButtonStyle} className='logout-button'
            onClick={(e) => {
              e.stopPropagation(); // Prevent click from closing the menu
              setIsModalOpen(true);
            }}
          >
            Logout
          </button>
        )}
      </div>

      {isModalOpen && (
        <Modal
          title="Confirm Logout"
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleModalSubmit}
          submitButtonText="Yes"
          showCancel={true}
          showSubmitButton={true}
          className="logout-modal"
        >
          <p className='nokids-error'>Are you sure you want to log out?</p>
        </Modal>
      )}
    </header>
  );
};

export default Header;
